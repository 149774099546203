import React from "react"
import Header from "../components/Header"
import Title from "../components/Title"
import Carousel from "../components/Carousel"
import Image from "../components/Image"
import { renderPictures } from "../utils/photos"

export default function Information() {
  return (
    <div className="ezds-information">
      <Header />
      <Title />
      <Carousel>{renderPictures()}</Carousel>
      <div className="ezds-information-footer">
        <Image
          fileName="info-footer.jpg"
          style={{ width: "100%", maxWidth: "700px" }}
          alt=""
        />
      </div>
    </div>
  )
}
